import React from 'react'
import { Navigate, useSearchParams } from 'react-router-dom'
import InvoiceProvider from './InvoiceProvider'
import InvoiceView from './InvoiceView'
import Main from '../Main/Main'
import { InvoiceFlagProvider } from '../InvoiceFlagProvider'

export default function InvoiceRoute() {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const orderGuid = searchParams.get('order')

  if (!token) {
    return <Navigate to={'/invoice'} />
  }

  return (
    <Main>
      <InvoiceProvider token={token!!} orderGuid={orderGuid || undefined}>
        <InvoiceFlagProvider>
          <InvoiceView />
        </InvoiceFlagProvider>
      </InvoiceProvider>
    </Main>
  )
}
