import envFromHostname from '@toasttab/env-from-hostname'

const env = envFromHostname(window.location.hostname)
const domain =
  env === 'prod'
    ? 'ws-api.toasttab.com'
    : env === 'preprod'
    ? 'ws-preprod.eng.toasttab.com'
    : 'ws-dev-api.eng.toastteam.com'

declare global {
  interface Window {
    __TOAST_BANQUET_INITIAL_DATA__: {
      session?: {
        id?: string
        issuedAt?: { dateTime: DateTime }
        expiresAt?: { dateTime: DateTime }
      }
    }
  }
}

export type DateTime = {
  date: { year: number; month: number; day: number }
  time: { hour: number; minute: number; second: number }
}

export async function fetchGraphql(query: string, variables: object) {
  const body = JSON.stringify({ query, variables })
  const options = {
    method: 'post',
    headers: {
      'Content-Type': 'application/json',
      'apollographql-client-name': `invoice-pay-web`,
      'apollographql-client-version': process.env.PKG_VERSION,
      'Toast-Session-ID':
        typeof window !== 'undefined'
          ? window.__TOAST_BANQUET_INITIAL_DATA__?.session?.id
          : null
    },
    body,
    credentials: 'include'
  }
  return fetch(
    `https://${domain}/do-federated-gateway/v1/graphql`,
    // @ts-ignore
    options
  ).then(async (res) => {
    const ret = await res.json()
    handleError(res, ret)
    return ret
  })
}

export async function api(
  method: string,
  path: string,
  headers = {},
  body?: any
) {
  const url = `https://${domain}/${path}`
  return fetch(url, {
    method: method,
    body: body,
    headers: headers
  }).then(async (res) => {
    const string = await res.text()
    const ret = string === '' ? {} : JSON.parse(string)
    handleError(res, ret)
    return ret
  })
}

export async function apiGet(path: string, headers = {}) {
  return api('GET', path, headers)
}

export async function apiPost(path: string, body?: any, headers = {}) {
  return api(
    'POST',
    path,
    {
      ...headers,
      'Content-Type': 'application/json'
    },
    body
  )
}

export async function apiPatch(path: string, body?: any, headers = {}) {
  return api(
    'PATCH',
    path,
    {
      ...headers,
      'Content-Type': 'application/json'
    },
    body
  )
}
export async function apiFilePost(path: string, body?: any, headers = {}) {
  return api('POST', path, headers, body)
}

export async function apiEmptyPost(path: string): Promise<Response> {
  return api('POST', path)
}

export const formatQueryParams = (obj: any) => {
  const query = obj as Record<string, string>
  Object.keys(query).forEach((key) => {
    if (query[key] === undefined || query[key] === null || query[key] === '') {
      delete query[key]
    }
  })
  return Object.keys(query).length === 0
    ? ''
    : `?${new URLSearchParams(query).toString()}`
}

function handleError(res: Response, ret: any) {
  if (isErrorResponse(res)) {
    throw ret
  }
}

function isErrorResponse(res: Response): boolean {
  return res.status < 200 || res.status > 299
}

export const brandingPublicAssetURL =
  env === 'prod'
    ? 'https://d28f3w0x9i80nq.cloudfront.net/'
    : 'https://d1knuqod36pnys.cloudfront.net/'
